
import CustomFileSystemProvider from 'devextreme/file_management/custom_provider';
import attachmentApi from "./AttachmentApi";
const typeTranslations = {
    'deficiencies': 'Mängel',
    'sigePlan': 'SiGePlan',
    'laterWork': 'Spätere Arbeiten',
    'signed': 'Unterschrieben',
    'order': 'Baustellenordnung',
    'protocol': 'Protokolle',
    'notice': 'Vorankündigung',
    'final': 'Finale Unterlage',    
}

let projectAttachmentProvider = function (scope) {
    let tempDirectories = [];
    return new CustomFileSystemProvider({
        getItems: async (i) => {
            let attachments = await attachmentApi.getAllByAssignment("project", scope.$root.project._id);

            let items = await Promise.all(attachments.filter(a => (a.type || '') == i.key).map(n => attachmentApi.getThumbnailBlob(n._id, 40, 40).then(blob => (blob&&blob.size>0)?URL.createObjectURL(blob):null).then(thumb => ({ name: n.name, isDirectory: false, size: n.size, attachment: n, dateModified: n.lastWrite, thumbnail: thumb,key:n._id }))));
            scope.$root.fileDirectory = i.key;
            // We only allow one level of hierachy - if /i/ is root we dont have a name
            let types = Array.from(new Set(attachments.map(n => n.type).concat(tempDirectories)));
            let folderPaths = !i.key ? (types.filter(t => t && !t.includes("/"))) : types.filter(t => t && t.startsWith(i.key + "/") && !t.substring(i.key.length + 1).includes("/"));
            let folders = folderPaths.filter(n =>!n.startsWith("_")).map(n => ({
                    key: n,
                    name: n in typeTranslations ? typeTranslations[n] : (n.split("/").findLast(()=>true)),
                    isDirectory: true
                }));
            items = items.concat(folders);
            return items;
        },
        uploadFileChunk: async (fileData, uploadInfo, destinationDirectory) => {
            if (fileData.uploaded)
                return;
            fileData.uploaded = true;
            let att = await attachmentApi.save( {
                type: destinationDirectory.key,
                assignmentType: "project",
                assignment: scope.$root.project._id,
                name: fileData.name
            })
            console.log("FILE UP", fileData, uploadInfo, destinationDirectory)
            await attachmentApi.saveBlob(att._id, fileData);
        },
        getItemsContent: async (items) => {
            console.log("[NO UNUSED] getItemsContent", items)
        },
        downloadItems: async (items) => {
            if (items.length > 0)
                await attachmentApi.downloadMultiple(items.map(item => item.dataItem.attachment._id));
        },
        deleteItem: async (item) => {
            await attachmentApi.delete(item.dataItem.attachment._id);
        },
        createDirectory: function (parentDir, name) {
            tempDirectories.push((parentDir.key ? (parentDir.key + "/") : "") + name);
        },
    })
}
export default projectAttachmentProvider;