<template>
    <div v-if="$root.project&&formData" class="area-content-body projects-sige-plan-planning">
        <div class="projects-sige-plan-planning-main">
            <div class="project-head" :class="{dirty:$root.isDirty}">
                <header-field-editor class="project-number-edit" :input-hint="'Projekt-Nr.'" v-model:data-source="$root.project.number" :allow-edit="false" />
                <div class="v-line"></div>
                <span class="headline">SiGe-Plan</span>
                <div>
                    <span class="head-toolbar-button-compact" @click="generateDocument" title="Dokument erstellen">description</span>
                </div>
                <div>
                    <span class="head-toolbar-button-compact" @click="generatePdf" title="PDF erstellen">picture_as_pdf</span>
                </div>
                <attachments-button />
            </div>
            <div class="projects-sige-plan-planning-scroll-contain">

                <div class="admin-list">
                    <div class="sige-template-select-contain">
                        <dx-select-box v-if="!planningExists" v-model:value="formData.projectTypeId" :items="projectTypes" @value-changed="getDataFromTemplate" display-expr="name" value-expr="_id" placeholder="Vorlage auswählen" />
                        <dx-button v-else-if="!formData || !formData.hazardGroups || formData.hazardGroups.length == 0" @click="getData">Daten aus Planungsphase übernehmen</dx-button>
                        <div v-else></div>
                        <div v-show="projectTypeTemplate && formData.hazardGroups && projectTypeTemplate.hazardGroups.some(hg=>!formData.hazardGroups.find(fhg=>fhg.id===hg.id))" class="head-toolbar-button-compact material-symbols-outlined" @click.prevent.stop="showRestore()" title="Wiederherstellen">auto_delete</div>
                    </div>
                    <div v-for="hazardGroup in formData.hazardGroups" :key="hazardGroup.id" class="admin-list-item" @click.prevent="selectGroup(hazardGroup.id)" :class="{'active':group&&group.id===hazardGroup.id}" title="Gruppe auswählen">
                        <div class="admin-list-item-button material-symbols-outlined" @click.prevent.stop="deleteGroup(hazardGroup)" title="Gruppe löschen">delete</div>
                        <div class="admin-list-item-content">{{hazardGroup.name}}</div>
                        <div v-show="projectTypeTemplate && formData.hazardGroups && projectTypeTemplate.hazardGroups.find(hg=>hg.id===hazardGroup.id).hazards.some(h=>!hazardGroup.hazards.find(fh=>fh.id===h.id))" class="admin-list-item-button material-symbols-outlined" @click.prevent.stop="showRestore(hazardGroup)" title="Gruppe wiederherstellen">auto_delete</div>
                    </div>
                </div>


                <dx-form :form-data="planningData" class="projects-sige-plan-form" :col-count="4" :show-validation-summary="true" label-mode="outside" label-location="top">
                    <dx-group-item css-class="group-with-bg" :col-span="2" caption="Notrufplan">
                        <dx-simple-item data-field="hospitalAddress" :col-span="2" editor-type="dxTextArea" :editor-options="{maxHeight:200,autoResizeEnabled:true,minHeight:100,maxLength:16000}" :label="{text:'Nächstes Krankenhaus'}" />
                        <dx-simple-item data-field="doctorsAddress" :col-span="2" editor-type="dxTextArea" :editor-options="{maxHeight:200,autoResizeEnabled:true,minHeight:100,maxLength:16000}" :label="{text:'Durchgangsarzt'}" />
                    </dx-group-item>
                    <dx-group-item css-class="group-with-bg" :col-span="2" caption="Bild Deckblatt">
                        <dx-simple-item>
                            <template #default>
                                <image-viewer :attachment="{assignment:$root.project._id,assignmentType:'project',name:'PlanCoverSheet.png'}" allowed-types="image/*" :allow-upload="true" :allow-drop="true" :allow-edit="true" :allow-clear="true" :allow-full-screen="true" height="200px"></image-viewer>
                            </template>
                        </dx-simple-item>
                    </dx-group-item>
                </dx-form>

                <dx-popup v-model:visible="restoreVisible" :width="900" :height="450" :hide-on-outside-click="true" :show-close-button="true" title="Elemente wiederherstellen">
                    <template #content>
                        <div v-if="restoreFromGroup && projectTypeTemplate && restoreVisible">
                            <dx-button v-for="hazard in projectTypeTemplate.hazardGroups.find(hg=>hg.id===restoreFromGroup.id).hazards.filter(h=>!restoreFromGroup.hazards.find(fh=>fh.id===h.id))" :key="hazard.id" :text="hazard.name" icon="undo" @click="restoreHazard(restoreFromGroup,hazard)" hint="Wiederherstellen" />
                        </div>
                        <div v-else-if="projectTypeTemplate && restoreVisible">
                            <dx-button v-for="hazardGroup in projectTypeTemplate.hazardGroups.filter(hg=>!formData.hazardGroups.find(fhg=>fhg.id===hg.id))" :key="hazardGroup.id" :text="hazardGroup.name" icon="undo" @click="restoreGroup(hazardGroup)" hint="Gruppe wiederherstellen" />
                        </div>
                    </template>
                </dx-popup>
            </div>
        </div>
        <div v-if="group" class="admin-project-type-hazard-group">
            <hazards-list v-model:items="group.hazards"
                          :read-only-fields="['name','safetyRegulation','note']"
                          :show-selection-controls="true"
                          :get-allowed-options="getAllowedHazardOptions" />
        </div>
    </div>
    <div v-else class="area-content-body">
        Daten werden geladen...
    </div>
</template>

<script lang="js">
    import { defineComponent } from 'vue';
    import HeaderFieldEditor from '../components/header-field-editor.vue';
    import AttachmentsButton from '../components/project-attachments-button.vue';
    import HazardsList from '../components/hazards-list.vue';
    import projectSiGePlanApi from '../services/ProjectSiGePlanApi';
    import projectTypeApi from '../services/ProjectTypeApi';
    import { confirm } from 'devextreme/ui/dialog'
    import projectTypeTemplateApi from '../services/ProjectTypeTemplateApi';
    import { isEqual } from 'lodash';
    import { DxPopup } from 'devextreme-vue/popup'
    import { DxSelectBox } from 'devextreme-vue/select-box';
    import { DxButton } from 'devextreme-vue/button';
    import { DxForm,DxSimpleItem,DxGroupItem} from 'devextreme-vue/form';
    import attachmentApi from '../services/AttachmentApi'
import projectSiGePlanPlanningApi from '../services/ProjectSiGePlanPlanningApi';
import notify from 'devextreme/ui/notify';
import { afterProcessingAsync } from '../services/Processor';
import ImageViewer from '../components/image-viewer.vue';


    export default defineComponent({
        components: {
            HazardsList,
            HeaderFieldEditor,
            AttachmentsButton,
            DxSelectBox,
            DxPopup,
            DxButton,
            ImageViewer,
            DxForm,DxSimpleItem,DxGroupItem
        },
        watch: {
            async '$route.params'() {
                if (this.$route.path.match(/\/sige-plan(\/|$)/i))
                    this.fetchData();
            },
            '$root.project': {
                async handler() {
                    this.fetchData();
                }
            },
            'formData': {
                async handler() {
                    if (this.projectSiGePlan && this.formData) {
                        if (!isEqual(this.projectSiGePlan, this.formData)) {
                            this.projectSiGePlan = await projectSiGePlanApi.save(structuredClone(this.formData));   
                        }
                    }
                },
                deep: true
            }
        },
        data() {
            return {
                formData: null,
                planningData: null,
                planningExists: false,
                projectSiGePlan: null,
                projectTypeTemplate: null,
                projectTypes: [],
                group: null,
                restoreFromGroup: null,
                restoreVisible: false
            };
        },
        async created() {
            if (this.$root.project && this.$route.params.id == this.$root.project._id) {
                this.fetchData();
            } else {
                this.formData = null;
                this.$root.loadProject(this.$route.params.id);
            }
        },
        methods: {
            async getDataFromTemplate() {
                let projectType = await projectTypeApi.get(this.formData.projectTypeId);
                let projectTypeTemplate = structuredClone(await projectTypeTemplateApi.get(projectType.projectTypeTemplateId));
                this.formData.hazardGroups = projectTypeTemplate.hazardGroups.filter(hg => projectType.selectedHazardGroups.includes(hg.id));
                for (let hazardGroup of this.formData.hazardGroups) {
                    if (!hazardGroup.hazards)
                        continue;
                    hazardGroup.hazards = hazardGroup.hazards.filter(h => !projectType.removedHazards.includes(h.id));
                    for (let hazard of hazardGroup.hazards) {
                        if (!hazard.measures)
                            continue;
                        hazard.measures = hazard.measures.filter(m => !projectType.removedMeasures.includes(m.id))
                        if (hazard.id in projectType.hazardAdjustments) {
                            for (let k in projectType.hazardAdjustments[hazard.id]) {
                                if (projectType.hazardAdjustments[hazard.id][k] !== null)
                                    hazard[k] = projectType.hazardAdjustments[hazard.id][k];
                            }
                        }
                    }
                }
                this.formData.projectId = this.$route.params.id;
                this.projectSiGePlan = structuredClone(await projectSiGePlanApi.save(this.formData))
                this.$nextTick(async () => {
                    await afterProcessingAsync()
                    this.fetchData();
                })                
            },
            async getData() {
                let planningData = structuredClone(await projectSiGePlanPlanningApi.get(this.$route.params.id));
                this.planningData = planningData;
                let currentData = await projectSiGePlanApi.get(this.$route.params.id);
                if (currentData) {
                    planningData._id = currentData._id;
                }
                await projectSiGePlanApi.save(planningData);
                await this.fetchData();
            },
            async fetchData() {
                window["adminGetData"] = this.getData;
                this.$root.fileDirectory = "sigePlan";
                this.projectTypes = await projectTypeApi.getAll();
                if (this.$route.params.id) {
                    this.projectSiGePlan = await projectSiGePlanApi.get(this.$route.params.id);
                    this.planningData = await projectSiGePlanPlanningApi.get(this.$route.params.id);
                    this.planningExists = structuredClone(this.planningData) != null;
                    console.log("projectSiGePlan", this.projectSiGePlan)
                    if (this.projectSiGePlan == null) {
                        this.formData = { projectTypeId: null };
                        return;
                    }
                    this.formData = structuredClone(this.projectSiGePlan);
                    let projectType = this.projectTypes.find(p => p._id == this.formData.projectTypeId)
                    if (projectType) {
                        this.projectTypeTemplate = await projectTypeTemplateApi.get(projectType.projectTypeTemplateId)
                    }
                    if (this.$route.params.group && this.formData) {
                        this.group = this.formData.hazardGroups.find(g => g.id === this.$route.params.group);
                    } else {
                        if (this.formData.hazardGroups) {
                            let g = this.formData.hazardGroups.find(() => true);
                            if (g)
                                this.selectGroup(g.id)
                        }
                    }
                }
            },
            async deleteGroup(i) {
                if (!this.formData.hazardGroups)
                    throw new Error("No HazardGroups!", this.formData);
                if (await confirm("Soll die Gruppe gelöscht werden?", "Gruppe Löschen?")) {
                    let index = this.formData.hazardGroups.findIndex(g => g.id === i.id)
                    this.formData.hazardGroups.splice(index, 1);
                    if (this.group.id == i.id)
                        this.selectGroup("");
                }
            },
            async restoreGroup(group) {
                if (!this.formData.hazardGroups)
                    throw new Error("No HazardGroups!", this.formData);
                if (this.formData.hazardGroups.find(hg => hg.id === group.id))
                    throw new Error("Already exists!", [this.formData, group]);
                this.formData.hazardGroups.push(structuredClone(group));
                // Sort by index in template
                this.formData.hazardGroups.sort((a, b) => this.projectTypeTemplate.hazardGroups.findIndex(hg => hg.id === a.id) - this.projectTypeTemplate.hazardGroups.findIndex(hg => hg.id === b.id))
            },
            async restoreHazard(group, hazard) {
                if (!this.formData.hazardGroups)
                    throw new Error("No HazardGroups!", this.formData);
                if (!this.formData.hazardGroups.find(hg => hg.id === group.id))
                    throw new Error("Group does not exist!", [this.formData, group]);
                let templateGroup = this.projectTypeTemplate.hazardGroups.find(hg => hg.id === group.id);
                if (group.hazards.find(h => h.id === hazard.id))
                    throw new Error("Already exist!", [group, hazard]);
                group.hazards.push(structuredClone(hazard));
                // Sort by index in template
                group.hazards.sort((a, b) => templateGroup.hazards.findIndex(h => h.id === a.id) - templateGroup.hazards.findIndex(h => h.id === b.id))
            },
            async selectGroup(id) {
                // Do not navigate if the page is already somewhere else!
                if (!this.$route.path.match(/\/sige-plan(\/|$)/i))
                    return;
                this.$router.push("/Projects/" + this.$root.project._id + "/sige-plan/" + id)
            },
            getAllowedHazardOptions(hazard) {
                if (this.projectTypeTemplate) {
                    let templateHazard = this.projectTypeTemplate.hazardGroups.map(hg => hg.hazards.find(h => h.id === hazard.id)).find(notEmpty => notEmpty);
                    if (!templateHazard || !templateHazard.measures)
                        return hazard.measures;
                    return templateHazard.measures.filter(m => hazard.measures?!hazard.measures.find(hm => hm.id === m.id):true).concat(hazard.measures);
                }
            },
            showRestore(group) {
                this.restoreVisible = true;
                this.restoreFromGroup = group;
            },
            async generateDocument() {
                try {
                    let pId = this.$root.project._id;
                    let att = await projectSiGePlanApi.generateDocument(pId);
                    this.$root.toast("SiGePlan", [{
                        icon: "download",
                        hint: "SiGePlan herunterladen",
                        onClick: async (t) => {
                            await attachmentApi.download(att._id);
                            t.remove();
                        }
                    }, {
                        icon: "picture_as_pdf",
                        hint: "PDF Erstellen",
                        onClick: async (t) => {
                            this.generatePdf(null, pId)
                            t.remove();
                        }
                    }], 5000);
                } catch {
                    notify("Fehler beim Erstellen der Unterlage", "error");
                }
            },
            async generatePdf(e, id) {       
                let att = await projectSiGePlanApi.finishDocument(id||this.$root.project._id);           
                this.$root.toast("SiGePlan PDF", [{
                    icon: "download",
                    hint: "SiGePlan PDF herunterladen",
                    onClick: async (t) => {
                        await attachmentApi.download(att._id);
                        t.remove();
                    }
                }], 5000);
            }
        }
    });
</script>


<style>
</style>

